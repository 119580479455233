<div class="__wrap" fxLayout="column">
  <header class="__top" fxLayout="row" fxLayoutAlign="end center">
    <ng-content></ng-content>
  </header>

  <div class="__content" fxFlex="1 1">
    <nav class="__nav" gdInline gdGap="0.9rem" gdAlignColumns="center">
      <a class="__link" routerLink="/" routerLinkActive="--active"
         [routerLinkActiveOptions]="{exact:true}" (click)="goToHome()">Home</a>
      <a class="__link" routerLink="/about-us" routerLinkActive="--active" (click)="closeMenu()">About Us</a>

      <a class="__link" routerLink="/services" routerLinkActive="--active" (click)="closeMenu()">Services</a>
      <div class="__sub-menu" gdGap=".6rem">
        <a class="__link --small" routerLink="/services"
           routerLinkActive="--active" (click)="closeMenu()">Overview
        </a>
        <a class="__link --small"
           [routerLink]="item.link"
           routerLinkActive="--active"
           *ngFor="let item of sunMenu1"
           (click)="closeMenu()">{{item.name}}</a>
      </div>

      <a class="__link" routerLink="/scrum-training" routerLinkActive="--active" (click)="closeMenu()">Scrum Training</a>

      <!-- <a class="__link" routerLink="/scrum-training" routerLinkActive="--active" (click)="closeMenu()">Scrum Training</a>
      <div class="__sub-menu" gdGap=" .6rem">
        <a class="__link --small" routerLink="/scrum-training"
           routerLinkActive="--active" (click)="closeMenu()">Overview
        </a>

        <a class="__link --small"
           [routerLink]="item.link"
           routerLinkActive="--active"
           *ngFor="let item of sunMenu2"
           (click)="closeMenu()">{{item.name}}
        </a>
      </div> -->

      <a class="__link" href="mailto:get-in-touch@agilebear.com?subject=Get in touch with Agilebear" gdAlignColumns="center">Contact Us</a>
    </nav>
  </div>

  <footer
    class="__soc"
    fxLayoutAlign="center center"
    fxLayoutGap="2.375rem"
    fxFlex="0 1 auto"
  >
    <div gdGap="1rem" gdAlignColumns="center" gdAlignRows="stretch">
	<agl-button routerLink="/event/booking-form" [ngClass]="{'--orange': true , '--yellow-orange': false}">Book Now</agl-button>

      <a class="__link --tell" href="tel:01173179269">0117 317 9269</a>
    </div>

    <!--  <a class="__soc-link" href="" target="_blank">-->
    <!--    <span class="agi-soc-inst"></span>-->
    <!--  </a>-->

    <!--  <a class="__soc-link" href="" target="_blank">-->
    <!--    <span class="agi-soc-fb"></span>-->
    <!--  </a>-->

    <!--  <a class="__soc-link" href="" target="_blank">-->
    <!--    <span class="agi-soc-ln"></span>-->
    <!--  </a>-->
  </footer>
</div>
