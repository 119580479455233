import {Component, HostBinding, HostListener, Input, OnInit} from '@angular/core';

/*
  Certified ScrumMaster Training
  Certified Product Owner Training
  The Cultural Change
  Advanced Certified ScrumMaster Training
  Advanced Certified Product Owner Training
  Enterprise Organisational Change For Coaches
  Certified Scrum Developer
  Testing On Agile Projects
  User Story Workshops
  Agile Coaching Sessions
  Lean Workshops
  Scrum Overview
  Certified Agile Skills - Scaling
  Kanban Overview
*/

const SERVICES_LOGOS = [
  {
    name:       'csm',
    img:        'csm.png',
    imgS:       'csm.png',
    caption:    'CSM',
    text:       'Certified ScrumMaster',
    colorClass: '--yellow'
  },
  {
    name:       'cspo',
    img:        'cspo.png',
    imgS:       'cspo.png',
    caption:    'CSPO',
    text:       'Certified Scrum Product Owner',
    colorClass: '--orange'
  },
  {
    name:       'tcc',
    img:        'tcc.png',
    imgS:       'tcc.png',
    caption:    'TCC',
    text:       'The Cultural Change',
    // colorClass: '--blue'
    colorClass: '--newcol06'
  },
  {
    name:       'a-csm',
    img:        'a_csm.png',
    imgS:       'a_csm.png',
    caption:    'A-CSM',
    text:       'Advanced Certified ScrumMaster',
    colorClass: '--yellow'
  },
  {
    name:       'a-cspo',
    img:        'a-cspo.png',
    imgS:       'a-cspo.png',
    caption:    'A-CSPO',
    text:       'Advanced Certified Scrum Product Owner',
    colorClass: '--orange'
  },
  {
    name:       'eocfc',
    img:        'eocfc.png',
    imgS:       'eocfc.png',
    caption:    'EOCFC',
    text:       'Enterprise Organisational Change for Coaches',
    // colorClass: '--yellow'
    colorClass: '--newcol03'
  },
  {
    name:       'csd',
    img:        'csd.png',
    imgS:       'csd.png',
    caption:    'CSD',
    text:       'Certified Scrum Developer',
    colorClass: '--red'
  },
  {
    name:       'toap',
    img:        'toap.png',
    imgS:       'toap.png',
    caption:    'TOAP',
    text:       'Testing On Agile Projects',
    // colorClass: '--green'
    colorClass: '--newcol08'
  },
  {
    name:       'usw',
    img:        'usw.png',
    imgS:       'usw.png',
    caption:    'USW',
    text:       'User Story Workshops',
    // colorClass: '--blue'
    colorClass: '--newcol07'
  },
  {
    name:       'acs',
    img:        'acs.png',
    imgS:       'acs.png',
    caption:    'ACS',
    text:       'Agile Coaching Sessions',
    // colorClass: '--green'
    colorClass: '--newcol02'
  },
  {
    name:       'lw',
    img:        'lw.png',
    imgS:       'lw.png',
    caption:    'LW',
    text:       'Lean Workshops',
    // colorClass: '--yellow'
    colorClass: '--newcol05'
  },
  {
    name:       'so',
    img:        'so.png',
    imgS:       'so.png',
    caption:    'SO',
    text:       'Scrum Overview',
    // colorClass: '--blue'
    colorClass: '--newcol01'
  },
  {
    name:       'cas-s',
    img:        'cas-s.png',
    imgS:       'cas-s.png',
    caption:    'CAS-S',
    text:       'Certified Agile Skills - Scaling',
    colorClass: '--newcol09'
  },
  {
    name:       'ko',
    img:        'ko.png',
    imgS:       'ko.png',
    caption:    'KO',
    text:       'Kanban Overview',
    colorClass: '--newcol04'
  },
  {
    name:       'cst',
    img:        'cst.png',
    imgS:       'cst.png',
    caption:    'CST',
    text:       'Certified Scrum Trainer',
    // colorClass: '--gray'
    colorClass: '--newcol00'
  }

  // {
  //   name:       'vso',
  //   img:        'agilebear_vso.png',
  //   imgS:       'agilebear_vso.png',
  //   caption:    'VSO',
  //   text:       'Scrum Overview',
  //   colorClass: '--orange'
  //   colorClass: '--new01'
  // },
  // {
  //   name:       'csp',
  //   img:        'csp.png',
  //   imgS:       'csp.png',
  //   caption:    'CSP',
  //   text:       'Certified Scrum Practitioner',
  //   colorClass: '--blue'
  // },
  // {
  //   name:       'rep',
  //   img:        'rep.png',
  //   imgS:       'rep.png',
  //   caption:    'REP',
  //   text:       'Some test',
  //   // colorClass: ''
  // },
  // {
  //   name:       'sd',
  //   img:        'sd.png',
  //   imgS:       'sd.png',
  //   caption:    'SD',
  //   text:       'Scrum Dragon',
  //   colorClass: '--green'
  // },
  // {
  //   name:       'csp-sm',
  //   img:        'csp-sm.png',
  //   imgS:       'csp-sm.png',
  //   caption:    'CSP-SM',
  //   text:       'Certified Scrum Professional - ScrumMaster',
  //   colorClass: '--blue'
  // },

];

@Component({
  selector: 'agl-services-logos',
  templateUrl: './services-logos.component.html',
  styleUrls: ['./services-logos.component.scss']
})
export class ServicesLogosComponent implements OnInit {

  @Input() usedLogo: any = [];
  @Input() isGray: boolean;
  @Input() popoverTrigger: boolean = false;

  list: any = SERVICES_LOGOS;

  constructor() {

  }

  state: number;

  // @HostListener('mouseover') isHover(): boolean {
  //   return true;
  // }

  ngOnInit(): void {
    this.list = SERVICES_LOGOS.filter((item) => {
      return this.usedLogo.filter((item2) => {
        return item2 === item.name;
      }).length !== 0;
    });
  }
}
